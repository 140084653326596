import moment from 'moment';
import _get from 'lodash/get';
import {
    animateSensorIcon,
    getStatusMessage,
    LABEL_KEY_DESCRIPTION,
    LABEL_KEY_NAME,
    RESERVED_LABEL_KEYS,
    mutedNotFiringAlertTypes,
    GOOD_CELLULAR_CONNECTION_THRESHOLD,
    cconIs2ndGen,
    getSupportLinkFromProductNumber
} from 'services/SensorHelper';
import { DEFAULT_TOKEN } from 'services/PaginationHelper';
import { scrollContainerToActiveChild, hasOwnProperty } from 'services/utils';
import { TRANSFER_DEVICE, UPDATE_DEVICE } from 'services/Permissions';
import { SHOW_MORE_DEVICE_DETAILS } from 'services/StudioProfileService';
import { SENSOR_CONNECTIVITY_PANEL_STATE_KEY } from 'components/sensors-and-connectors/components/sensor-details/sensor-details.controller';

import imgTinySensor from '../../../../assets/images/img_tiny_sensor.svg';
import imgCCON from '../../../../assets/images/img_ccon.png';
import imgCCONv2 from '../../../../assets/images/img_ccon_v2.png';
import imgEthernet from '../../../../assets/images/img_ethernet.svg';
import imgCellular from '../../../../assets/images/img_cellular.svg';
import { States } from '../../../../app.router';

import DeactivateModalController from '../deactivate-modal/controller';
import DeactivateModalTemplate from '../deactivate-modal/template.html';

/* @ngInject */
export default class ThingDetailsController {
    constructor(
        EventEmitter,
        Loader,
        $q,
        SensorService,
        $scope,
        ToastService,
        TranslationService,
        StudioProfileService,
        ProjectManager,
        $timeout,
        $state,
        $stateParams,
        StateService,
        RoleManager,
        IAMService,
        DialogService
    ) {
        this.eventEmitter = EventEmitter;
        this.Loader = Loader;
        this.$q = $q;
        this.SensorService = SensorService;
        this.evtSource = null;
        this.$scope = $scope;
        this.ToastService = ToastService;
        this.ProjectManager = ProjectManager;
        this.TranslationService = TranslationService;
        this.StudioProfileService = StudioProfileService;
        this.$timeout = $timeout;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.StateService = StateService
        this.RoleManager = RoleManager;
        this.IAMService = IAMService;
        this.DialogService = DialogService;

        this.onUpdateReceived = this.onUpdateReceived.bind(this);
        this.onReorderCallback = this.listReorder.bind(this);
    }

    get canTransferSensors() {
        return this.RoleManager.can(TRANSFER_DEVICE);
    }

    get canUpdate() {
        return this.RoleManager.can(UPDATE_DEVICE);
    }

    get networkConnectionIcon() {
        const connection = _get(this.thing, 'reported.connectionStatus.connection', 'OFFLINE');

        if (connection === 'CELLULAR') {
            return imgCellular;
        }

        if (connection === 'ETHERNET') {
            return imgEthernet;
        }

        return null;
    }

    get isEmulated() {
        return this.thing.id.startsWith('emu')
    }

    get isTouchSensor() {
        return this.thing.type === 'touch';
    }

    get isUpdatedLongAgo() {
        return moment(this.thing.state.updated).isBefore(moment().subtract(1, 'day'));
    }

    get statusMessage() {
        return getStatusMessage(this.thing);
    }

    get supportLinkFromProductNumber() {
        return getSupportLinkFromProductNumber(this.thing.productNumber)
    }

    get isBoostMode() {
        return _get(this.thing, 'reported.networkStatus.transmissionMode', null) === 'HIGH_POWER_BOOST_MODE';
    }

    get usingCellular() {
        const connection = _get(this.thing, 'reported.connectionStatus.connection', 'OFFLINE');
        return connection === 'CELLULAR'
    }

    get hasGoodCellularConnection() {
        const signalStrength = _get(this.thing, 'reported.cellularStatus.signalStrength', 0);
        return signalStrength >= GOOD_CELLULAR_CONNECTION_THRESHOLD
    }

    get cellularAvailable() {
        return _get(this.thing, 'reported.connectionStatus.available', []).includes('CELLULAR');
    }

    get ethernetAvailable() {
        return _get(this.thing, 'reported.connectionStatus.available', []).includes('ETHERNET');
    }

    get numberOfProjects() {
        return Object.keys(this.sensorsInProjects).length
    }

    get mutedNotFiringAlertTypes() {
        if (!this.thing.alertsStatus) {
            return null;
        }
        return mutedNotFiringAlertTypes(this.thing.alertsStatus);
    }

    toggleConnectivityPanel() {
        this.connectivityVisible = !this.connectivityVisible;
        this.StateService.setItem(SENSOR_CONNECTIVITY_PANEL_STATE_KEY, this.connectivityVisible)
    }

    $onInit() {
        this.alerts = [];
        this.history = [];
        this.events = [];

        this.seenSensorIds = []
        this.sensorsInProjects = {} 
        this.selectedProjectId = this.ProjectManager.currentProjectId
        this.orderBy = 'labels.name'
        this.currentPageToken = DEFAULT_TOKEN
        this.nextPageToken = null
        this.loadedProjects = false
        this.loadSensorsSeen()

        this.editMode = false;
        this.connectivityVisible = this.StateService.getItem(SENSOR_CONNECTIVITY_PANEL_STATE_KEY, false)
        this.showMore = this.StudioProfileService.get(SHOW_MORE_DEVICE_DETAILS) || false

        this.subscribeToLiveUpdates();
        
        this.activatingDevice = false // Loading indicator while we're activating

        this.imgTinySensor = imgTinySensor;
        if (cconIs2ndGen(this.thing)) {
            this.imgCCON = imgCCONv2
        } else { 
            this.imgCCON = imgCCON;
        }

        this.chartType = this.thing.type;
        this.showChart = false;

        // Local copy of the name and description to be able to detect no change on ng-blur
        this.cconNameLabel = this.thing.labels.name ? this.thing.labels.name : ''
        this.cconDescriptionLabel = this.thing.labels.description ? this.thing.labels.description : ''

        this.cconIconNode = document.querySelector('#ccon-icon');

        this.filterLabels();

        this.$timeout(scrollContainerToActiveChild);
        
        this.fetchWarranty()

        setTimeout(() => { // Wait a tick for the DOM to be ready before auto-resizing the description
            this.autoResizeDescription()
        }, 1);
    }

    loadSensorsSeen() {
        this.loadingDevices = true
        this.SensorService.listSensorsSeen(this.thing.id).then(({ sensors }) => {
            this.seenSensorIds = sensors.filter(resourceName => !resourceName.startsWith('projects/-/')) // Remove sensors with a dash in the name since the user don't have access to them
            this.loadProjects()
        })
    }

    loadProjects() {
        // Divide devices into projects to be able to fetch in bulk
        this.sensorsInProjects[this.ProjectManager.currentProject.id] = {'devices': [], 'deviceIds': []} // Initialize current project
        this.seenSensorIds.forEach(sensor => {
            const projectId = sensor.split('/')[1]
            const deviceId = sensor.split('/')[3]
            if (!hasOwnProperty(this.sensorsInProjects, projectId)) {
                this.sensorsInProjects[projectId] = {'devices': [], 'deviceIds': []} // Initialize project
            }

            this.sensorsInProjects[projectId].deviceIds.push(deviceId) // Keep full list of deviceIds for each project
        })

        // Fetch project names
        const promises = [] // Use promises to indication loading in UI
        Object.keys(this.sensorsInProjects).forEach(projectId => {
            promises.push(this.IAMService.getProject(projectId).then(project => {  
                this.sensorsInProjects[projectId].displayName = project.displayName
                this.sensorsInProjects[projectId].id = projectId

                // Only load devices for the current project
                if (projectId === this.selectedProjectId) {
                    this.loadSensors(this.sensorsInProjects[this.selectedProjectId].deviceIds)
                }
            }).catch(serverResponse => {
                console.error(serverResponse) // eslint-disable-line no-console
            }))
        })

        // Wait for all promises to resolve before updating UI
        Promise.all(promises).then(() => {
            this.loadedProjects = true
            this.$scope.$applyAsync()
        })
    }

    selectProject(projectId) {
        this.loadingDevices = true
        this.selectedProjectId = projectId
        this.currentPageToken = DEFAULT_TOKEN
        this.loadSensors(this.sensorsInProjects[this.selectedProjectId].deviceIds)   
    }

    loadSensors(deviceIds) {
        if (deviceIds.length === 0) {
            this.loadingDevices = false
            return
        }

        if (deviceIds.length > 2000) {
            this.ToastService.showSimpleTranslated('ccon_seen_sensors_cant_be_loaded')
            this.loadingDevices = false
            return
        }
        
        this.SensorService.sensors({
            deviceIds,
            orderBy: this.orderBy, 
            pageSize: 100, 
            pageToken: this.currentPageToken
        }, this.selectedProjectId).then(({ data, nextPageToken}) => {

            // Ensure reported networkStatus is from the Cloud Connector we're viewing
            data.forEach(sensor => {
                const networkStatus = sensor.reported.networkStatus.cloudConnectors.find(ccon => ccon.id === this.thing.id);
                // There's a slight possibility that the sensor just got a heartbeat for a different set of Cloud Connectors.
                // In this case we won't find the Cloud Connector in the list for the networkStatus, so just leave it as-is.
                if (networkStatus) {
                    sensor.reported.networkStatus.rssi = networkStatus.rssi;
                    sensor.reported.networkStatus.signalStrength = networkStatus.signalStrength;
                }
            })
            
            this.sensorsInProjects[this.selectedProjectId].devices = data
            this.nextPageToken = nextPageToken
            this.subscribeToLiveSensorUpdates(deviceIds, this.selectedProjectId)
        }).catch(serverResponse => {
            console.error(serverResponse) // eslint-disable-line no-console
            this.ToastService.showSimpleTranslated('sensor_list_wasnt_loaded', {
                serverResponse
            })
        }).finally(() => {
            this.loadingDevices = false
        })
    }

    // Subscribe to live updates for all sensors in the list
    subscribeToLiveSensorUpdates(deviceIds, projectId) {
        this.unsubscribeFromLiveSensorUpdates()
        this.liveSensorSubscription = this.SensorService.subscribeToAllUpdates({ deviceIds, projectId }, this.onSensorUpdateReceived.bind(this))
    }

    onSensorUpdateReceived(event) {

        const eventType = event.eventType;
        const sensorId = event.targetName.split('/')[3]
        const sensor = this.sensorsInProjects[this.selectedProjectId].devices.find(device => device.id === sensorId)
        
        // Only update the networkStatus if it's from the Cloud Connector we're viewing
        if (eventType === 'networkStatus' && event.data.networkStatus.cloudConnectors.find(ccon => ccon.id === this.thing.id)) {
            sensor.reported.networkStatus.rssi = event.data.networkStatus.rssi
            sensor.reported.networkStatus.signalStrength = event.data.networkStatus.signalStrength
            sensor.lastSeen = event.timestamp
        }

        if (eventType === 'touch') {
            this.rippleOnListSensor(sensorId)
        }
        this.$scope.$applyAsync();
    }

    unsubscribeFromLiveSensorUpdates() {
        if (this.liveSensorSubscription) {
            this.liveSensorSubscription.stop();
        }
    }

    copySensorIds() {
        const sensorIds = this.sensorsInProjects[this.selectedProjectId].deviceIds
        const sensorIdsString = sensorIds.join(',')
        navigator.clipboard.writeText(sensorIdsString).then(() => {
            this.ToastService.showSimpleTranslated('sensor_ids_copied')
        }).catch(() => {
            this.ToastService.showSimpleTranslated('sensor_ids_not_copied')
        })
    }

    setPageToken({ pageToken }) {
        this.currentPageToken = pageToken;
        this.loadSensors(this.sensorsInProjects[this.selectedProjectId].deviceIds)
    }

    listReorder(order) {
        this.orderBy = order
        this.currentPageToken = DEFAULT_TOKEN
        this.loadSensors(this.sensorsInProjects[this.selectedProjectId].deviceIds)
    }

    goToSensorPage(sensor) {
        this.$state.go(States.SENSOR_DETAILS, {
            projectId: sensor.name.split('/')[1],
            sensorId: sensor.name.split('/')[3],
        });
    }

    fetchWarranty() {
        this.loadingWarrantyInfo = true // Loading indicator while we're fetching
        this.thing.isUnderWarranty = null // Default to null, so we can show a "No information" message if needed
        this.SensorService.warrantyInfo([this.thing.id]).then(({ warrantyInfo }) => {
            const deviceWarrantyInfo = warrantyInfo.find(device => device.deviceId === this.thing.id)
            if (deviceWarrantyInfo) {
                this.thing.isUnderWarranty = deviceWarrantyInfo.isUnderWarranty
                this.thing.warrantyEndTime = deviceWarrantyInfo.warrantyEndTime ? moment(deviceWarrantyInfo.warrantyEndTime).format('MMM D, YYYY') : null
            }
        }).catch((serverResponse) => {
            console.error(serverResponse) // eslint-disable-line no-console
        }).finally(() => {
            this.loadingWarrantyInfo = false
        })
    }

    toggleShowMore() {
        this.showMore = !this.showMore
        this.StudioProfileService.set(SHOW_MORE_DEVICE_DETAILS, this.showMore)
    }

    rippleOnCcon() {
        animateSensorIcon(this.cconIconNode);
    }

    rippleOnListSensor(sensorId) { // eslint-disable-line class-methods-use-this
        const sensorIconNode = document.querySelector(`.cell-${sensorId}-icon`);
        if (sensorIconNode) {
            animateSensorIcon(sensorIconNode);
        }
    };

    $onDestroy() {
        this.unsubscribeFromLiveUpdates();
    }

    $onChanges(changes) {
        if (changes.thingUpdate && !changes.thingUpdate.isFirstChange()) {
            this.thing.starred = changes.thingUpdate.currentValue.starred;
        }
    }

    onUpdateReceived(event) {
        if (event.targetName !== this.thing.name) {
            return;
        }

        if (event.eventType === 'labelsChanged') {
            this.thing.labels = Object.keys(this.thing.labels)
                .filter(key => event.data.removed.indexOf(key) < 0)
                .reduce((acc, key) => {
                    acc[key] = Object.prototype.hasOwnProperty.call(event.data.modified, key)
                        ? event.data.modified[key]
                        : this.thing.labels[key];
                    return acc;
                }, {});

            Object.keys(event.data.added).forEach((key) => {
                this.thing.labels[key] = event.data.added[key];
            });
        } else {
            const eventType = event.eventType;

            this.$timeout(() => {
                this.lastEvent = event;
            }, 0);

            this.thing.reported = {
                ...this.thing.reported,
                [eventType]: event.data[eventType]
            };
            if (eventType === 'touch') {
                this.rippleOnCcon();
                this.connectivityUpdating = true;
            }
            if (eventType === 'networkStatus') {
                this.connectivityUpdating = false;
            }
        }

        this.$scope.$applyAsync();
    }

    unsubscribeFromLiveUpdates() {
        if (this.liveDataSubscription) {
            this.liveDataSubscription.stop();
        }
    }

    subscribeToLiveUpdates() {
        this.unsubscribeFromLiveUpdates();

        this.liveDataSubscription = this.SensorService.subscribeToThingUpdates(
            this.thing.id,
            this.onUpdateReceived
        );
    }

    onChangeThingStarred({ starred }) {
        this.thing.starred = starred;
        this.onThingUpdated(
            this.eventEmitter({
                thing: this.thing
            })
        );
    }

    filterLabels() {
        this.filteredLabels = Object.keys(this.thing.labels)
            .filter(labelKey => RESERVED_LABEL_KEYS.indexOf(labelKey) < 0)
            .reduce((acc, labelKey) => {
                acc[labelKey] = this.thing.labels[labelKey];
                return acc;
            }, {});
    }

    createLabel({ label }) {
        return this.SensorService.createLabel(this.thing.name, label)
            .then(({ key, value }) => {
                this.thing.labels[key] = value;
                this.ToastService.showSimpleTranslated('label_was_created');
                this.filterLabels();
            })
            .catch((serverResponse) => {
                this.ToastService.showSimpleTranslated('label_wasnt_created', {
                    serverResponse
                });
            });
    }

    updateName() {

        // Don't update if the name hasn't changed
        if (this.cconNameLabel === this.thing.labels[LABEL_KEY_NAME]) { return null }

        if (Object.prototype.hasOwnProperty.call(this.thing.labels, LABEL_KEY_NAME)) {
            const promise = this.updateLabel({
                labelKey: LABEL_KEY_NAME,
                value: this.cconNameLabel
            });
            this.Loader.promise = promise;
            return promise;
        }

        const promise = this.createLabel({
            label: {
                key: LABEL_KEY_NAME,
                value: this.cconNameLabel
            }
        });
        this.Loader.promise = promise;
        return promise;
    }

    updateDescription() {

        // Don't update if the description hasn't changed
        if (this.cconDescriptionLabel === this.thing.labels[LABEL_KEY_DESCRIPTION]) { return null }

        if (Object.prototype.hasOwnProperty.call(this.thing.labels, LABEL_KEY_DESCRIPTION)) {
            const promise = this.updateLabel({
                labelKey: LABEL_KEY_DESCRIPTION,
                value: this.cconDescriptionLabel
            });
            this.Loader.promise = promise;
            return promise;
        }

        const promise = this.createLabel({
            label: {
                key: LABEL_KEY_DESCRIPTION,
                value: this.cconDescriptionLabel
            }
        });
        this.Loader.promise = promise;
        return promise;
    }

    autoResizeDescription() {
        // Automatically resize the textarea to fit the content to improve UX
        const textarea = document.getElementById('auto-resize-textarea')
        textarea.style.height = 'auto'
        if (this.cconDescriptionLabel === undefined || this.cconDescriptionLabel === '') {
            textarea.style.height = `${32}px`
        } else {
            textarea.style.height = `${textarea.scrollHeight}px`
        }
    }

    updateLabel({ labelKey, value }) {

        // Not waiting for request to update UI, reverting back to 'previousValue' if the request fails
        let previousValue = ''
        if (!RESERVED_LABEL_KEYS.includes(labelKey)) {
            previousValue = this.filteredLabels[labelKey]
            this.filteredLabels[labelKey] = value
        }

        return this.SensorService.updateLabel(this.thing.name, labelKey, { value })
            .then((label) => {
                this.thing.labels[labelKey] = label.value;
                this.ToastService.showSimpleTranslated('label_was_updated');
                this.filterLabels();
            })
            .catch((serverResponse) => {
                this.filteredLabels[labelKey] = previousValue // Revert back to previous value
                this.ToastService.showSimpleTranslated('label_wasnt_updated', {
                    serverResponse
                });
            });
    }

    removeLabel({ labelKey }) {
        return this.SensorService.deleteLabel(this.thing.name, labelKey)
            .then(() => {
                delete this.thing.labels[labelKey];
                this.ToastService.showSimpleTranslated('label_was_removed');
                this.filterLabels();
            })
            .catch((serverResponse) => {
                this.ToastService.showSimpleTranslated('label_wasnt_removed', {
                    serverResponse
                });
            });
    }

    onAlertsEnabledToggled() {
        if (this.thing.alertsStatus.alertsEnabled) {
            return this.SensorService.enableAlerts(this.thing.name)
                .then(() => {
                    return this.ToastService.showSimpleTranslated('alerts_was_enabled');
                })
                .catch((serverResponse) => {
                    this.ToastService.showSimpleTranslated('alerts_wasnt_enabled', {
                        serverResponse
                    });
                });
        }
        return this.SensorService.disableAlerts(this.thing.name)
            .then(() => {
                // Disabling alerts implicitly deletes firing alerts and muted alert types
                this.thing.alertsStatus.firingAlerts.length = 0;
                this.thing.alertsStatus.mutedAlertTypes.length = 0;
                return this.ToastService.showSimpleTranslated('alerts_was_disabled');
            })
            .catch((serverResponse) => {
                this.ToastService.showSimpleTranslated('alerts_wasnt_disabled', {
                    serverResponse
                });
            });
    }

    muteAlert(alertType) {
        return this.SensorService.muteAlert(this.thing.name, alertType)
            .then(() => {
                return this.ToastService.showSimpleTranslated('alert_was_muted');
            })
            .catch((serverResponse) => {
                this.ToastService.showSimpleTranslated('alert_wasnt_muted', {
                    serverResponse
                });
            });
    }

    unmuteAlert(alertType) {
        return this.SensorService.unmuteAlert(this.thing.name, alertType)
            .then(() => {
                this.ToastService.showSimpleTranslated('alert_was_unmuted');
            })
            .catch((serverResponse) => {
                this.ToastService.showSimpleTranslated('alert_wasnt_unmuted', {
                    serverResponse
                });
            });
    }

    updateAlertMute(alertType, muted) {
        if (muted) {
            return this.muteAlert(alertType);
        }
        return this.unmuteAlert(alertType);
    }

    activateDevice() {
        this.activatingDevice = true
        this.SensorService.activateDevice(this.thing.id).then(() => {
            this.thing.active = true
            this.ToastService.showSimpleText('Cloud Connector successfully activated')
            this.$scope.$applyAsync()
        }).catch((error) => {
            const errorMessage = error.data ? error.data.error : 'An error occurred'
            this.ToastService.showSimpleText(errorMessage)
        }).finally(() => {
            this.activatingDevice = false
        })
    }

    showDeactivateModal() {
        this.DialogService.show({
            controller: DeactivateModalController,
            controllerAs: '$ctrl',
            template: DeactivateModalTemplate,
            parent: document.body,
            clickOutsideToClose: true,
            fullscreen: true,
            locals: {
                device: this.thing,
                successfullyDeactivated: this.successfullyDeactivated.bind(this)
            }
        });
    }

    successfullyDeactivated() {
        this.thing.active = false
        this.showMore = false
        this.$scope.$applyAsync()
    }

    moveSensor(event) {
        this.onMoveSensor(
            this.eventEmitter({
                sensors: [this.thing.id],
                event
            })
        ).then((data) => {
            if (!data) {
                return;
            }
            const { transferredDevices } = data;
            if (transferredDevices && transferredDevices.length) {
                this.$state.go(States.SENSORS);
            }
        });
    }
}
