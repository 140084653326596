import QrScanner from 'qr-scanner';
import { PREFERRED_CAMERA } from 'services/utils';
import * as SensorTypes from 'services/config/SensorTypes';
import imgKitID from '../../assets/images/img_scan_id.png';
import { States } from '../../app.router';
import { UPDATE_ORGANIZATION } from 'services/Permissions';
import { cconIsEthernetOnlyModel } from 'services/SensorHelper';

import AddLicensesController from '../add-licenses-modal/controller';
import AddLicensesTemplate from '../add-licenses-modal/template.html';

/* @ngInject */
export default class ClaimDevicesController {
    constructor(ProjectManager, $rootScope, ToastService, DialogService, IAMService, SensorService, refreshDeviceList, StorageService, AnalyticsService, $state, RoleManager, FeatureFlags) {
        this.ProjectManager = ProjectManager;
        this.$rootScope = $rootScope;
        this.ToastService = ToastService;
        this.DialogService = DialogService;
        this.IAMService = IAMService;
        this.SensorService = SensorService;
        this.refreshDeviceList = refreshDeviceList;
        this.StorageService = StorageService;
        this.AnalyticsService = AnalyticsService;
        this.$state = $state;
        this.RoleManager = RoleManager;
        this.FeatureFlags = FeatureFlags;
    }

    get numberOfDevices() {
        let sum = 0
        this.claimItems.forEach(item => {
            if (!item.isClaimed) {
                sum += item.claimableCount
            }
        });
        return sum
    }

    get validIdentifier() {

        if (this.showQRSection) {
            return this.kitRegex.test(this.identifierInput) || this.deviceRegex.test(this.identifierInput)
        } 
        return this.activeRegex.test(this.identifierInput)   
    }


    get QRCodeColor() { // Live overlay color shown on QR codes
        if (this.QRData.length === 0) {
            return ''
        }
        const index = this.claimItems.findIndex(item => item.id === this.QRData)
        if (index !== -1) {
            if (this.claimItems[index].name === '-') {
                return 'white' // Loading device/kit details
            } 
            if (!this.claimItems[index].isClaimed) {
                return 'green' 
            }
        }
        return 'red' // Can't be claimed
    }

    get canAdjustLicenses() {
        return this.RoleManager.hasOrganizationPermissionTo(UPDATE_ORGANIZATION);
    }

    get updatedAvailableSensorLicenses() {
        // Updates the available licenses based on the number of devices to be activated
        if (this.activateDevices) {
            let sensorsToActivateCount = 0
            this.claimItems.forEach(item => {
                if (item.type === 'DEVICE' && !item.isClaimed && item.deviceType !== 'ccon') {
                    sensorsToActivateCount += 1
                }
                if (item.type === 'KIT' && !item.isClaimed) {
                    item.devices.forEach(device => {
                        if (device.isClaimed === false && device.deviceType !== 'ccon') {
                            sensorsToActivateCount += 1
                        }
                    })
                }
            })
            this.tooFewSensorLicenses = this.availableSensorLicenses - sensorsToActivateCount < 0
                
            return this.availableSensorLicenses - sensorsToActivateCount
        }
        return this.availableSensorLicenses
    }

    get updatedAvailableCellularLicenses() {
        // Updates the available licenses based on the number of devices to be activated
        if (this.activateDevices) {
            let cellularToActivateCount = this.claimItems.filter(item => item.deviceType === 'ccon').length
            this.claimItems.forEach(item => {
                if (item.type === 'KIT' && !item.isClaimed) {
                    item.devices.forEach(device => {
                        if (!device.isClaimed && device.deviceType === 'ccon' && !cconIsEthernetOnlyModel(device)) {
                            cellularToActivateCount += 1
                        }
                    })
                }
            })
            this.tooFewCellularLicenses = this.availableCellularLicenses - cellularToActivateCount < 0

            return this.availableCellularLicenses - cellularToActivateCount
        }
        return this.availableCellularLicenses
    }

    get hasNewBillingModel() {
        return this.FeatureFlags.isActive('billing_new_billing_model')
    }

    $onInit() {
        this.identifierInput = ''
        this.project = this.ProjectManager.currentProject;
        this.claimItems = []
        this.fetchingDetails = false
        this.inputType = "KIT"
        this.inputPlaceholder = "E.g. ABC-42-DEF"
        this.kitRegex = /^([a-zA-Z0-9]{2,}-?[a-zA-Z0-9]{2,}-?[a-zA-Z0-9]{2,}\s?)+$/ // Kit ID and old claim-code. Allows multiple with ' ' separation.
        this.deviceRegex = /^([a-zA-Z0-9]{20,23}\s?)+$/ // Device ID (emulated also). Allows multiple with ' ' separation.
        this.activeRegex = this.kitRegex

        this.claimInProgress = false
        this.claimLoadingPercentage = 0
        this.claimLoadingDescription = 'Adding devices, this can take a few seconds...'

        this.showQRSection = false
        this.scanForQR = true
        this.QRData = ''

        this.detectedUnknownQR = false
        this.detectedDeviceNoAccess = false

        this.imgKitID = imgKitID

        this.scanner = null
        this.startedScanning = false
        this.selectedCamera = this.StorageService.getItem(PREFERRED_CAMERA) ?? 'environment'
        this.cameraList = []
        this.analyticsCurrentCamera = null;
        this.kitDeviceIds = new Set() // Unique set of all devices in scanned kits. Prevent showing double claimable if both a device and parent kit is scanned.

        // Lets the user decide if devices should be activated or just claimed
        this.activateDevices = true

        this.availableSensorLicenses = null
        this.availableCellularLicenses = null

        if (this.hasNewBillingModel) {
            this.IAMService.getAvailableLicenses().then(response => {
                this.availableSensorLicenses = response.sensorLicenses
                this.availableCellularLicenses = response.cconLicenses  
            })
        }

        // Determine if the user has too few licenses to claim the devices
        this.tooFewSensorLicenses = false
        this.tooFewCellularLicenses = false
    }

    setInputType(type) {
        this.inputType = type
        if (this.inputType === 'KIT') {
            this.AnalyticsService.trackEvent("claiming.switch_to_kit_id.user")
            this.inputPlaceholder = 'E.g. ABC-42-DEF'
            this.activeRegex = this.kitRegex
        } else {
            this.AnalyticsService.trackEvent("claiming.switch_to_device_id.user")
            this.inputPlaceholder = 'E.g. b6sfpst7rihg0dm4v01g'
            this.activeRegex = this.deviceRegex
        }
    }

    inputChanged() {
        // Automatically switch to Device ID input if a full XID is detected
        if (this.activeRegex === this.kitRegex && this.deviceRegex.test(this.identifierInput)) {
            this.AnalyticsService.trackEvent("claiming.switch_to_device_id.auto")
            this.setInputType('DEVICE')
        }
    }

    verifyIdentifier(identifierInput, orgId, fromQrScan = false) {

        if (this.validIdentifier) {

            const inputClaimItems = identifierInput.split(' ') // Support for adding multiple IDs with ' ' separation
            inputClaimItems.forEach(claimIdentifier => {
                if (this.claimItems.findIndex(item => item.id === claimIdentifier) !== -1) { // Already added to list
                    // Track that the current camera was able to scan a device or kit
                    if (fromQrScan && this.analyticsCurrentCamera) {
                        this.analyticsCurrentCamera.successfulScans += 1;
                    }
                    return
                }
    
                // If the ID passes the regex test, add it to the list as "Fetching details..."
                this.fetchingDetails = true
                this.claimItems.push({
                    id: claimIdentifier,
                    name: '-',
                    deviceType: null,
                    isClaimed: false,
                    claimableCount: 0,
                    claimedCount: 0,
                    kitDevices: []
                })
                this.$rootScope.$applyAsync(); // Nudge UI to update
    
                this.IAMService.getClaimDetails(claimIdentifier, orgId).then(response => {
    
                    // A valid Kit ID was provided
                    const itemIndex = this.claimItems.findIndex(item => item.id === claimIdentifier)
                    this.claimItems[itemIndex].type = response.type
                    if (response.type === 'DEVICE') {
                        // Track that the current camera was able to scan a device or kit
                        if (fromQrScan) {
                            if (this.analyticsCurrentCamera) {
                                this.analyticsCurrentCamera.successfulScans += 1;
                            }
                            this.AnalyticsService.trackEvent(`claiming.qr_scan.device_scanned.${response.device.isClaimed ? "already_claimed" : "unclaimed"}`)
                        }

                        this.claimItems[itemIndex].name = SensorTypes[response.device.deviceType].title
                        this.claimItems[itemIndex].deviceType = response.device.deviceType
                        this.claimItems[itemIndex].isClaimed = response.device.isClaimed
                        // Prevent being shown as claimable if parent kit is already added
                        this.claimItems[itemIndex].claimableCount = !this.claimItems[itemIndex].isClaimed && !this.kitDeviceIds.has(claimIdentifier) ? 1 : 0 
                    } 
                    else if (response.type === 'KIT') {
                        const devices = response.kit.devices
                        
                        // Stop showing previously added single devices from this kit as claimable in the list
                        devices.forEach(device => {
                            this.claimItems[itemIndex].kitDevices.push(device.deviceId)
                            this.kitDeviceIds.add(device.deviceId)
                            const itemToUpdateIndex = this.claimItems.findIndex(item => item.id === device.deviceId) 
                            if (itemToUpdateIndex > -1) {
                                this.claimItems[itemToUpdateIndex].claimableCount = 0
                            }
                        });
                        this.claimItems[itemIndex].devices = devices
                        this.claimItems[itemIndex].name = response.kit.displayName
                        this.claimItems[itemIndex].claimedCount = devices.filter(device => device.isClaimed).length
                        this.claimItems[itemIndex].isClaimed = this.claimItems[itemIndex].claimedCount === devices.length
                        if (!this.claimItems[itemIndex].isClaimed) {
                            this.claimItems[itemIndex].claimableCount = devices.length - this.claimItems[itemIndex].claimedCount
                        }

                        // Track that the current camera was able to scan a device or kit
                        if (fromQrScan) {
                            if (this.analyticsCurrentCamera) {
                                this.analyticsCurrentCamera.successfulScans += 1;
                            }
                            let status = "";
                            if (this.claimItems[itemIndex].claimedCount === devices.length) {
                                status = "already_claimed"
                            } else if (this.claimItems[itemIndex].claimedCount === 0) {
                                status = "unclaimed"
                            } else {
                                status = "partially_claimed"
                            }
                            this.AnalyticsService.trackEvent(`claiming.qr_scan.kit_scanned.${status}`)
                        }
                    }

                    // Kits or devices that have already been fully claimed will be shown in the list to give the user context, 
                    // but are shown with a strike-through for the name
                    if (this.claimItems[itemIndex].isClaimed) {
                        this.ToastService.showSimpleTranslated('claiming_already_claimed', { hideDelay: 6000 })
                    }

                    this.identifierInput = ''
                    this.fetchingDetails = false
                    this.$rootScope.$applyAsync();

                }).catch(serverResponse => {

                    setTimeout(() => { // Visual delay to convey the error
                        this.ToastService.showSimpleTranslated('', { serverResponse, hideDelay: 6000 })
                        this.fetchingDetails = false
                        this.removeKit(claimIdentifier)
                    }, 1000);
                })
            })


        }
    }

    removeKit(identifierInput) {
        this.QRData = ''
        // Remove device/kit from list
        const itemIndex = this.claimItems.findIndex(item => item.id === identifierInput)
        if (itemIndex > -1) {
            // Update claimable status for any single devices previously determined to be included in a kit
            this.claimItems[itemIndex].kitDevices.forEach(deviceId => {
                this.kitDeviceIds.delete(deviceId)
                // Update single device to now be shown as claimable
                const itemToUpdateIndex = this.claimItems.findIndex(item => item.id === deviceId)
                if (itemToUpdateIndex > -1) {
                    this.claimItems[itemToUpdateIndex].claimableCount = this.claimItems[itemToUpdateIndex].isClaimed ? 0 : 1
                }
            })
            this.claimItems.splice(itemIndex, 1);
        }
    }

    // Returns a promise that resolves when all the requested devices were found in the project.
    // The request will timeout after maxRetries * waitInterval, plus however long the
    // requests themselves takes. Note that a timeout will result in a resolve, not reject.
    waitForDevicesToBeClaimed(deviceIds, maxRetries, waitInterval) {
        return new Promise((resolve, reject) => {

            // Fetches only the requested devices in the current project. If we get all
            // the devices, we are done waiting. Otherwise, try again.
            this.SensorService.sensors({ deviceIds }).then(response => {

                // Check if all the devices has been claimed successfully
                if (response.data.length === deviceIds.length) {
                    this.AnalyticsService.trackEvent("claiming.all_devices_claimed_successfully")
                    setTimeout(() => {
                        resolve();
                    }, 500);
                    return
                }

                // Check if we've reached the last retry
                if (maxRetries === 1) {
                    // Timed out while waiting for the devices to be transferred
                    // to the current project. This could be because:
                    // * The devices were wrongly already claimed in 
                    //   thing-aggregate, causing a nack loop in billing-service.
                    // * Unexpected latency issues, in which case they will
                    //   show up in the project eventually.
                    // 
                    // Just resolving the promise here which will indicate to the
                    // user that everything went as expected, and refresh the device
                    // list, but the devices will most likely not show up there.
                    // TODO: Consider if we want to show some sort of error in a 
                    // toast in this scenario.
                    this.AnalyticsService.trackEvent("claiming.timed_out_waiting_for_devices")
                    resolve()
                    return
                }

                // Still waiting for some devices to be claimed.
                // Retry again after a configurable wait interval.
                setTimeout(() => {
                    this.waitForDevicesToBeClaimed(deviceIds, maxRetries - 1, waitInterval)
                        .then(resolve)
                        .catch(reject);
                }, waitInterval)
            }).catch(error => {
                // Failed to get the list of devices
                this.AnalyticsService.trackEvent("claiming.failed_to_claim_devices")
                reject(error);
            })
        })
    }

    claim() {
        // Start a loading animation 
        this.claimInProgress = true
        const loadingInterval = setInterval(() => {
            if (this.claimLoadingPercentage < 90) {
                this.claimLoadingPercentage += 1
                this.$rootScope.$applyAsync()
            }
        }, 75);

        const kitIds = []
        const deviceIds = []
        this.claimItems.forEach(item => {
            if (!item.isClaimed) {
                if (item.type === 'KIT') {
                    kitIds.push(item.id)
                }
                if (item.type === 'DEVICE') {
                    deviceIds.push(item.id)
                }
            }
        });

        if (deviceIds.length === 1 && kitIds.length === 0) {
            this.AnalyticsService.trackEvent("claiming.start_claim.single_device")
        } else if (deviceIds.length > 1 && kitIds.length === 0) {
            this.AnalyticsService.trackEvent("claiming.start_claim.multiple_devices")
        } else if (deviceIds.length === 0 && kitIds.length === 1) {
            this.AnalyticsService.trackEvent("claiming.start_claim.single_kit")
        } else if (deviceIds.length === 0 && kitIds.length > 1) {
            this.AnalyticsService.trackEvent("claiming.start_claim.multiple_kits")
        } else if (deviceIds.length > 0 && kitIds.length > 0) {
            this.AnalyticsService.trackEvent("claiming.start_claim.kits_and_devices")
        }

        this.IAMService.claim(kitIds, deviceIds, !this.activateDevices).then(response => {

            if (deviceIds.length > 0) {
                this.AnalyticsService.trackEvent("claiming.devices_claimed", deviceIds.length);
            }
            if (kitIds.length > 0) {
                this.AnalyticsService.trackEvent("claiming.kits_claimed", kitIds.length);
            }

            // Limiting to only check if one page of devices has been successfully claimed.
            const maxDevicesToWaitFor = 100
            const devicesToCheck = response.claimedDevices.map(device => device.deviceId)
            devicesToCheck.splice(maxDevicesToWaitFor, response.claimedDevices.length - maxDevicesToWaitFor)

            // Waiting for the devices to show up in our current project
            this.waitForDevicesToBeClaimed(devicesToCheck, 15, 500).then(() => {

                // Trigger the claim completed animation 
                clearInterval(loadingInterval)
                this.claimLoadingPercentage = 100
                this.claimLoadingDescription = 'Successfully added devices'
                this.$rootScope.$applyAsync()

                // Refresh the list of devices in the background while showing the animation.
                this.refreshDeviceList() // Provided by parent controller

                // Show a toast with the number of devices that got claimed
                this.ToastService.showSimpleTranslated('claiming_claiming_completed', { hideDelay: 6000 }, {
                    deviceCount: response.claimedDevices.length
                });

                // Wait before closing the modal to give the animation a chance to complete.
                setTimeout(() => {
                    // Go directly to device details page if claiming only one device
                    if (response.claimedDevices.length === 1) {
                        this.$state.go(States.SENSOR_DETAILS, {
                            projectId: this.project.id,
                            sensorId: response.claimedDevices[0].deviceId
                        })
                    }
                    this.closeModal()
                }, 1000)
            }).catch(serverResponse => {
                // Got error while waiting for the claimed devices to be transferred into 
                // the current project.
                clearInterval(loadingInterval)
                this.claimInProgress = false
                this.claimLoadingPercentage = 0
                this.ToastService.showSimpleTranslated('', { serverResponse, hideDelay: 6000 })
                this.$rootScope.$applyAsync()
            })
        }).catch(serverResponse => {
            // Failed to claim
            this.AnalyticsService.trackEvent("claiming.claim_failed")
            clearInterval(loadingInterval)
            this.claimInProgress = false
            this.claimLoadingPercentage = 0
            this.ToastService.showSimpleTranslated('', { serverResponse, hideDelay: 6000 })
            this.$rootScope.$applyAsync()
        })
    }

    startCamera() {
        QrScanner.hasCamera().then(hasCamera => {
            if (hasCamera) {
                this.showQRSection = true
                this.$rootScope.$applyAsync(); // Nudge UI to update
                this.setupScanning()
            } else {
                this.ToastService.showSimpleTranslated('no_camera');
                this.AnalyticsService.trackEvent("claiming.qr_scan.no_camera");
            }
        })
    }

    setupScanning() {
        setTimeout(() => { // Ensures the video element can read its final size

            const videoElement = document.getElementById('videoElement');

            this.scanner = new QrScanner(videoElement, result => {
                // Don't check QR code it the same as we have or we're already checking one
                if (result.data && result.data !== this.QRData && !this.fetchingDetails) {
                    this.QRData = result.data
                    this.identifierInput = this.QRData
                    this.$rootScope.$applyAsync() // Nudge UI to update
                    this.verifyIdentifier(this.QRData, this.project.organization.split("/")[1], true)
                }
            }, {
                calculateScanRegion: video => {

                    const width = video.videoWidth
                    const height = video.videoHeight

                    // Reduce the scan area to a centered square
                    const smallestDimension = Math.min(width, height)
                    const scanRegionSize = Math.round(0.35 * smallestDimension)
                    return {
                        x: (width - scanRegionSize) / 2,
                        y: (height - scanRegionSize) / 2,
                        width: scanRegionSize,
                        height: scanRegionSize
                    }

                },
                highlightScanRegion: true, // Style overridden in CSS
                highlightCodeOutline: true, // Style overridden in CSS
                preferredCamera: this.selectedCamera,
                onDecodeError: () => {
                    // Need to override to prevent the library from printing to console
                }
            });

            this.scanner.start().then(() => {
                QrScanner.listCameras(true).then(cameras => cameras.forEach(camera => {
                    this.cameraList.push(camera)
                })).finally(() => {
                    this.startedScanning = true
                    this.cameraList.forEach(camera => {
                        // Special handling for iPhones with multiple cameras, choosing the one with best up close focus.
                        if (this.selectedCamera === 'environment' && camera.label === 'Back Ultra Wide Camera') {
                            this.selectedCamera = camera.id
                            this.setCamera(camera.id)
                        }
                    })
                    this.$rootScope.$applyAsync()
                })
            })
        }, 600);
    }

    setCamera(facingModeOrId) {
        if (facingModeOrId.length > 0 && this.startedScanning) {

            if (this.analyticsCurrentCamera?.id !== facingModeOrId) {
                // The camera has changed. Check if the previous camera (if any) was able to scan a device
                this.userIsDoneWithCurrentCamera();

                // Use the name of the selected camera if possible. Otherwise it's possible the user has
                // selected the default camera, and we can't know which specific camera that is. In this
                // case, just include the full list of cameras.
                let cameraName = this.cameraList.find(c => c.id === facingModeOrId)?.label
                if (!cameraName) {
                    cameraName = `${facingModeOrId}(${this.cameraList.map(c => c.label).join(", ")})`
                }

                this.analyticsCurrentCamera = {
                    id: facingModeOrId,
                    name: cameraName,
                    timestampSelected: new Date(),
                    successfulScans: 0, // Represents scans of either kits or devices
                }
            }

            // Save the selected camera as the preferred one in local storage
            this.StorageService.setItem(PREFERRED_CAMERA, facingModeOrId)
            this.scanner.setCamera(facingModeOrId)
        }
    }

    userIsDoneWithCurrentCamera() {
        if (!this.analyticsCurrentCamera) {
            return;
        }

        // If the user has been trying to scan a devices for 10 or more seconds without finding anything,
        // we'll track that the camera they're using does not work well with scanning QR codes.
        const expectedTimeBeforeUserGivesUpScanning = 10000; // 10 seconds
        const timeSinceCameraWasSelected = new Date() - this.analyticsCurrentCamera.timestampSelected;

        if (timeSinceCameraWasSelected > expectedTimeBeforeUserGivesUpScanning && this.analyticsCurrentCamera.successfulScans === 0) {
            this.AnalyticsService.trackEvent(`claiming.qr_scan.camera_unsuccessful.${this.analyticsCurrentCamera.name}`);
        }
    }

    closeQRSection() {
        this.QRData = ''
        this.identifierInput = ''
        this.cameraList = []
        this.scanner.stop()
        this.showQRSection = false

        this.userIsDoneWithCurrentCamera();
        this.analyticsCurrentCamera = null;
    }

    showAddLicensesModal() {
        // Load both billing plan and licenses in use before showing the dialog
        Promise.all([
            this.IAMService.getBillingPlan(),
            this.SensorService.licensesInUse()
        ]).then(([plan, licensesInUse]) => {
            this.billingPlan = plan;
            this.licensesInUse = licensesInUse;
            this.closeModal()

            // Now show the dialog with the loaded data
            this.DialogService.show({
                controller: AddLicensesController,
                controllerAs: '$ctrl',
                template: AddLicensesTemplate,
                parent: document.body,
                clickOutsideToClose: true,
                escapeToClose: true,
                fullscreen: false,
                locals: {
                    billingPlan: this.billingPlan,
                    licensesInUse: this.licensesInUse
                }
            });
        }).catch(error => {
            const errorMessage = error.data ? error.data.error : 'Could not load the necessary data, please try again later.';
            this.ToastService.showSimpleText(errorMessage);
        });
    }

    closeModal() {
        if (this.scanner) {
            this.scanner.stop()
        }
        this.DialogService.cancel()
    }

    $onDestroy() {
        this.userIsDoneWithCurrentCamera();

        if (this.scanner) {
            this.scanner.stop()
        }
    }
}