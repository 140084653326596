import moment from 'moment';
import Config from 'services/config/local';
import { loadStripeSDK } from 'services/LazyLoading';

// Inform ES Lint that "Stripe" is a global variable (avoids linter errors)
/* global Stripe */

/* @ngInject */
export default class AddLicensesController {
    constructor(DialogService, ToastService, billingPlan, licensesInUse, $scope) {
        this.DialogService = DialogService;
        this.ToastService = ToastService;

        this.billingPlan = billingPlan
        this.licensesInUse = licensesInUse
        this.$scope = $scope
    }

    get daysUntilRenewal() {
        const today = moment();
        const renewal = moment(this.renewalDate);
        return renewal.diff(today, 'days');
    }

    get renewalDateString() {
        const renewalMoment = moment(this.renewalDate);
        return `${renewalMoment.format('MMMM Do')}`;
    }

    get formattedStudioPlan() {
        return this.billingPlan.studioPlan.charAt(0) + this.billingPlan.studioPlan.slice(1).toLowerCase()
    }

    get showProratedSection() {
        return this.sensorLicenses.nextRenewal > this.sensorLicenses.currentlyOwned || this.cellularLicenses.nextRenewal > this.cellularLicenses.currentlyOwned;
    }

    $onInit() {

        // Use the billing plan to set the initial values used for calculating the new values
        this.sensorLicenses = {
            inUse: this.licensesInUse.sensors,
            currentlyOwned: this.billingPlan.sensorLicenses,
            nextRenewal: this.billingPlan.sensorLicensesForRenewal,
            originalNextRenewal: this.billingPlan.sensorLicensesForRenewal,
            add: 0,
            newTotal: this.billingPlan.sensorLicensesForRenewal
        };
        this.cellularLicenses = {
            inUse: this.licensesInUse.cellular,
            currentlyOwned: this.billingPlan.cconLicenses,
            nextRenewal: this.billingPlan.cconLicensesForRenewal,
            originalNextRenewal: this.billingPlan.cconLicensesForRenewal,
            newTotal: this.billingPlan.cconLicensesForRenewal,
            add: 0
        };

        this.showCheckout = false;

        this.editSensorLicenses = false;
        this.editCellularLicenses = false;

        this.pricing = {
            sensor: this.billingPlan.centsPerSensorPerYear / 100,
            cellular: this.billingPlan.centsPerCconPerYear / 100,
        };

        this.renewalDate = new Date(this.billingPlan.nextRenewalDate);

        this.oldAnnualPrice = this.sensorLicenses.currentlyOwned * this.pricing.sensor + this.cellularLicenses.currentlyOwned * this.pricing.cellular;
        this.newAnnualPrice = null;
        this.proratedPrice = null;

        this.updateProratedPrice();
    }

    convertToCurrency(value) {
        return value.toLocaleString(undefined, { style: 'currency', currency: this.billingPlan.currency }); // Uses user locale for formatting when set to 'undefined'
    }

    updateProratedPrice() {

        if (this.sensorLicenses.nextRenewal < this.sensorLicenses.inUse) {
            this.proratedPrice = null
            return
        }
        if (this.cellularLicenses.nextRenewal < this.cellularLicenses.inUse) {
            this.proratedPrice = null
            return
        }

        this.sensorLicenses.newTotal = this.sensorLicenses.nextRenewal
        this.cellularLicenses.newTotal = this.cellularLicenses.nextRenewal
        this.sensorLicenses.add = Math.max(0, this.sensorLicenses.nextRenewal - this.sensorLicenses.currentlyOwned);
        this.cellularLicenses.add = Math.max(0, this.cellularLicenses.nextRenewal - this.cellularLicenses.currentlyOwned);

        const newAnnualPriceCheck = this.sensorLicenses.newTotal * this.pricing.sensor + this.cellularLicenses.newTotal * this.pricing.cellular;
        if (newAnnualPriceCheck === this.oldAnnualPrice) {
            this.newAnnualPrice = null;
        } else {
            this.newAnnualPrice = this.sensorLicenses.newTotal * this.pricing.sensor + this.cellularLicenses.newTotal * this.pricing.cellular;
        }

        const newSensorLicenses = this.sensorLicenses.add
        const newCellularLicenses = this.cellularLicenses.add
        const sensorCost = (newSensorLicenses * this.pricing.sensor * this.daysUntilRenewal) / 365;
        const cellularCost = (newCellularLicenses * this.pricing.cellular * this.daysUntilRenewal) / 365;
        this.proratedPrice = Math.round((sensorCost + cellularCost) * 100) / 100;
    }

    incrementLicenses(type, increment) {
        if (type === 'sensor') {
            this.sensorLicenses.nextRenewal += increment
        } else if (type === 'cellular') {
            this.cellularLicenses.nextRenewal += increment
        }
        this.updateProratedPrice();
    }

    confirmLicenses() {
        this.readyToPurchase = true;
        this.editSensorLicenses = false;
        this.editCellularLicenses = false;
    }

    cancelPurchaseFlow() {
        this.readyToPurchase = false;
        if (this.sensorLicenses.nextRenewal !== this.sensorLicenses.originalNextRenewal) {
            this.editSensorLicenses = true;
        }
        if (this.cellularLicenses.nextRenewal !== this.cellularLicenses.originalNextRenewal) {
            this.editCellularLicenses = true;
        }
    }

    async purchaseLicenses() {
        
        // TODO: Allow customer to choose between CARD or INVOICE (if applicable)
        const paymentMethod = "CARD";
        // TODO: Get the subsidiary from the offer
        const subsidiary = "US";
        // TODO: Remove when testing is done
        const fakeItTilYouMakeIt = true;
        
        let clientSecret;
        if (fakeItTilYouMakeIt) {
            // this.showCheckout = true;
            clientSecret = "cs_test_a1ME2Rq95A6bZobUEbCKgjryi4OYOXxfTNkm9I3FHEZFXpOyn7oRX6E4ND_secret_fid1d2BpamRhQ2prcSc%2FJ0p1YGslVmRrdicpJ3BsSGphYCc%2FJ2BoZ2BhYWBhJyknaWR8anBxUXx1YCc%2FJ3Zsa2JpYFpscWBoJyknd2BhbHdgZnFKa0ZqaHVpYHFsamsnPydrYHNgdycpJ2dkZm5id2pwa2FGamlqdyc%2FJyZjY2NjY2MneCUl";
        } else {
            // Request the purchase of new licenses. This will set up an offer and an invoice in 
            // Netsuite. If the payment method is CARD, a payment URL will be returned which in
            // turn can be converted to an embedded checkout. If the payment method is INVOICE,
            // the invoice will be returned and the customer will need to pay it manually.
            const purchaseResp = await this.IAMService.purchaseLicenses({
                paymentMethod: paymentMethod,
                sensorLicenses: this.sensorLicenses.add,
                cellularLicenses: this.cellularLicenses.add,
                estimatedProratedPriceCents: Math.round(this.proratedPrice * 100),
            })

            // TODO: Only supporting CARD for now
            if (!purchaseResp.cardPayment) {
                this.ToastService.error("Error retrieving payment information");
                return;
            }

            // Convert the payment URL to a client secret to be used with the embedded checkout
            const embeddedCheckoutURL = `${purchaseResp.cardPayment.paymentUrl}?embed=true`;
            const embedResp = await fetch(embeddedCheckoutURL)
            if (!embedResp.ok) {
                this.ToastService.error("Error retrieving payment information");
                return;
            }
            const embedData = await embedResp.json();
            clientSecret = embedData.clientSecret;
        }

        this.showCheckout = true;
        // Load the Stripe SDK (only if payment method is CARD)
        await loadStripeSDK();
        const stripeKey = subsidiary === "US" ? Config.stripe.usPublishableKey : Config.stripe.euPublishableKey;
        const stripeClient = Stripe(stripeKey);
        
        this.checkout = await stripeClient.initEmbeddedCheckout({
            fetchClientSecret: () => {
                return clientSecret;
            },
            onComplete: () => {
                console.log('Checkout complete'); // eslint-disable-line no-console
            }
        });
        this.checkout.mount('#stripe-checkout');
    }

    closeModal() {
        this.DialogService.cancel();
    }

    $onDestroy() {
        if (this.checkout) {
            this.checkout.destroy();
        }
    }
}